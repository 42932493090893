<template>
  <section class="row row-top">
    <div class="b-g h-100 w-100 img-cover"></div>
    <div class="col">
      <div class="container">
        <div class="row pb-5 pt-5">
          <div class="col">
            <h1 class="m-0">{{$route.params.id}}</h1>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="container ">
    <div class="row mt-3">
      <boxProduct v-for="item in categoryProducts" :key="item.id" :item="item" />
    </div>
    <alsoLove :key="$route.params.id" />
  </div>
</template>

<script>
  // @ is an alias to /src
  import boxProduct from '@/components/boxProduct.vue'
  import alsoLove from '@/components/alsoLove.vue'

  export default {
    name: 'CategoryPage',
    components: {
      boxProduct,
      alsoLove
    },
    data() {
      return {}
    },
    computed: {
      products() {
        return this.$store.state.products
      },
      categoryProducts() {
        return this.$store.state.products.filter((val) => {
          return val.category === this.$route.params.id;
        });
      }
    }
  }
</script>

<style scoped>
  .row-top {
    position: relative;
    /* height: 400px; */
    /* padding: 100px 0px 40px 0px; */
    text-align: center;
    color: #444;
    border-bottom: solid #444 3px;
    border-top: solid #444 3px;
  }

  .b-g {
    position: absolute;
    top: 0;
    right: 0;
    background-image: url('https://cdn.pixabay.com/photo/2015/04/25/03/09/cork-738603_960_720.jpg');
    opacity: .6;
    z-index: -1;
  }

  h1 {
    font-weight: 800;
    font-size: 50px;
    text-shadow: 2px 2px 20px rgb(0, 0, 0);
    color: #fff;
  }

  @media (max-width: 767.98px) {
    h1 {
      font-size: 30px;

    }
  }
</style>